@font-face {
  font-family: "Voyage";
  src: url(../static/fonts/Voyage-Regular.woff2);
}

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300&family=Odibee+Sans&family=Libre+Baskerville:ital@1&family=Questrial&display=swap');

*
{
    margin: 0;
    padding: 0;
}

body
{
  height: 100%;
  min-height: 100%;
  width: 100%;
  color: #000;
  background-color: #fff;
}

a {
  color: inherit;
  text-decoration: none;
}

#menu-button {
  position: fixed;
  height: 24px;
  width: 24px;
  background: white;
  top: 24px;
  left: 24px;
  z-index: 100;
  border-radius: 100%;
  transition: 0.6s ease-in;
  mix-blend-mode: difference;
  cursor: pointer;
}

#menu-button:hover {
  height: 28px;
  width: 28px;
}


#menu {
  height: 100vh;
  width: 0;
  background-color: #151515;
  font-family: 'Questrial', sans-serif;
  font-size: 64px;
  font-weight: 800;
  color: white;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  transition: width ease-in 0.7s;
  /* visibility: hidden; */
  z-index: 5;
  white-space: nowrap;
}

#menu ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  padding: 0 24px;
  height: 100%;
}

#menu ul li {
  padding: 24px 0;
  transition: color ease 0.4s;
}
#menu ul li:hover {
  color: #629292;
}

.email {
  font-size: 24px;
}

/* html,
body
{
    overflow: hidden;
} */

/* .webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
} */

.artist-name
{
    text-align: center;
    height: 60px;
    position: absolute;
    z-index: 4;
}

.artist-name h1
{
    font-family: 'Voyage', Georgia, 'Times New Roman', Times, serif;
    position: fixed;
    left: 0;
    right: 0;
    top: 10px;
    font-size: 40px;
    text-align: center;
    /* cursor: help; */
    /* color: white; */
    mix-blend-mode: difference;
    letter-spacing: 0.1em;
}

.artist-info {
    display: none;
}

.wrapper {
    margin: 0 100px;
    z-index: 1;
    position: relative;
}

p {
  font-family: "Questrial", sans-serif;
}

.artwork {
  height: 100vh;
  margin-bottom: 360px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 1;
}

.artwork-title {
    font-size: 20px;
    padding-bottom: 24px;
}

.artwork-description {
  max-width: 240px;
}

.artwork-image {
  height: 60vh;
}

#artist-statement {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 200px;
  height: 100vh;
  font-style: italic;
}

#artist-statement p {
  font-family: 'Libre Baskerville', serif;
  text-align: center;
  font-size: 20px;
  margin: 20px 0;
}

#artist-statement a {
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
    .wrapper {
    margin: 0;
    padding: 0 24px;
    }

    #menu {
      font-size: 24px;
    }

    .email {
      font-size: 12px;
    }

    #menu-button {
      height: 16px;
      width: 16px;
      top: 18px;
      left: 18px;
    }

    #menu-button:hover {
      height: 16px;
      width: 16px;
    }   
  
    .artist-name h1 {
      font-size: 24px;
    }

    .artwork {
      flex-direction: column-reverse;
      margin-bottom: 0;
      justify-content: center;
      align-items: flex-start;
    }

    .artwork-description {
      margin-top: 24px;
    }

    .artwork-title {
      font-size: 16px;
      padding-bottom: 8px;
    }

    .descriptive-group {
      font-size: 12px;
    }

    .artwork-image {
      height: 100%;
      width: 100%;
    }

    #artist-statement {
      margin: 40px;
    }

    #artist-statement p {
      margin: 10px 0;
      font-size: 12px;
    }
}